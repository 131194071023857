/*Inter google font*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
body{
    font-family: 'Satoshi', sans-serif;
    background-color: #FFFAF2;
    overflow-y: hidden;
}
.block h3{
    margin: 0;
    padding: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    /*limit text to 2 lines*/
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 220px;
    margin-top: 0;
}
.block>p{
    margin-top: 0;
    margin: 0;
    color:#6B7280;
    font-size: 14px;
}
.block{
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    font-family: 'Inter', sans-serif;
    padding: 25px;
}
.block.processed{
}
.block.error{
}
.type{
    display: block;
    background-color: #D1FAE5;
    color:#457024 !important;
    padding: 3px 12px 4px 12px;
    border-radius: 12px;
    margin-right: 5px;
    width: fit-content;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    font-family: 'Inter', sans-serif;
    margin: 0;
    margin-top: 3px;
    cursor: pointer;
}
.type.news{
    background-color: #FDE68A;
    color:#B45309 !important;
}
.type.loading{
    background-color: #FFDC6F;
    color:#B45309 !important;
}
.type.error{
    background-color: #FECACA;
    color:#991B1B !important;
}
.type.resource{
    background-color: #BFDBFE;
    color:#1E3A8A !important;
}
/* Making the blocks a grid, and no overflow text */
.blocks{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    border-top: 1px solid #E5E7EB;
    padding-top: 15px;
}
.blocks .block{
    overflow: hidden;
}
/* red delete button similar to the tag but rectangular
.delete, .archive, .activate{
    display: block;
    background-color: #E93345;
    color:#FFF;
    padding: 3px 12px 4px 12px;
    margin-right: 5px;
    margin-top: 20px !important;
    width: fit-content;
    cursor: pointer;
    font-family: 'Satoshi', sans-serif;
    border-radius: 6px;
    border:0;
}
.archive{
    background-color: #FFC200;
    color:#000;
}
.activate{
    background-color: #00B341;
    color:#FFF;
}*/
.buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
}
.buttons button{
    background-color: #FFF8E1;
    border:0;
    font-family: 'Satoshi', sans-serif;
    cursor: pointer;
    box-sizing: border-box;
    width: 38px;
    height: 35px;
    margin-right: 5px;
    border-radius: 6px;
    padding: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    
}
.buttons button img{
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    
}
.buttons button:hover{
    background-color: #FFDC6F;

}
.block img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 10px;
    max-height: 150px;
}
.block .heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.newsletter{
    margin-top: 20px;
    border-radius: 8px;
    background-color: #FFF;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.newsletter:hover{
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.2), 0px 4px 6px -2px rgba(0, 0, 0, 0.1);
    /* transition */
    transition: all 0.2s ease-in-out;

}
.newsletters{ /** grid*/
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    border-top: 1px solid #E5E7EB;
    padding-top: 15px;

}
.newsletter img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    height: 200px;
}
.newsletter-main{
    padding: 20px;
    
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.newsletter-title{
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /*limit text to 2 lines*/
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.newsletter-name{
    color: #BF9202;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    margin-bottom: 8px;
}
.newsletter-date{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: 'Inter', sans-serif;
    color :#6B7280;
    flex-grow:1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}
.newsletter-content{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color : #6B7280;
    margin-top: 10px;
    height: 120px;
}
.newsletter-loading-overlay { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.loading-text{
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    margin-left: 10px;
}
.container{
    display: flex;
}
.progress{
    width: 80%;
    height: 15px;
    background-color: #E5E7EB;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.progress-bar{
    height: 100%;
    background-color: #FFC200;
    border-radius: 8px;
}
.usage-bar .progress{
    height: 10px;
    width: 100%;
}
.sidebar{
    width: 300px;
    margin-right: 20px;
    background: url('/public/bg.jpg');
    background-repeat: repeat;
    background-size: cover;
    padding: 30px;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}
#logo{
    display: block;
    width: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin: 0;
}
.menu a{
    display: block;
    color: #fff;
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 21px;
    padding: 12px 20px;
    border-radius: 8px;
    /*align text on middle*/
    display: flex;
    align-items: center;
}
.menu a:hover{
    background-color: #FFC200;
    color: #000;
    font-weight: 600;
}
.menu{
    margin-top: 15px;
    flex-grow: 1;
}
.menu a img{
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin-right: 10px;
    color: #fff;
}
.menu a:hover img{
    filter: invert(1) grayscale(1) brightness(5);
}
.menu a.active{
    background-color: #FFC200;
    color: #000;
    font-weight: 600;
}
.menu a.active img{
    filter: invert(1) grayscale(1) brightness(5);
}
.main h1{
    font-size: 28px;
}
.main {
    flex-grow: 1;
    padding: 30px;
    overflow-y: scroll;
    max-height: 100vh;
    box-sizing: border-box;
}
.tabs{
    display: flex;
    margin-bottom: 0px;
    margin-top: -2px;
    flex-grow: 1;
}
.tabs .tab{
    display: block;
    color: #6B7280;
    margin-right: 10px;
    font-size: 14px;
    line-height: 21px;
    padding: 12px 20px;
    /*align text on middle*/
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid transparent;
}
.tabs .tab.active{
    border-bottom:2px solid #FFC200;
    color: #FFC200;
    font-weight: 600;
}
.blockshead{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: -20px;
}
.generate{
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #FFF;
    background: #0A0B1E;
    border-radius: 12px;
    border:0;
    padding: 13px 30px;
    cursor:pointer;
    margin-bottom: 12px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.generate:hover{
    background-color: #FFC200;
    color: #000;
}
.generate.transparent{
    border: 2px solid #FFF;
}
.generate.transparent:hover{
    background-color: #FFF;
    color: #000;
}
.generate img{
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin-right: 10px;
    color:#FFF;
}
.generate:hover img{
    filter: invert(1) grayscale(1) brightness(5);
}
.description{
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #6B7280;
    margin-bottom: 20px;
}
.description.small{
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: #6B7280;
    margin-bottom: 10px;
}
.setting-tab{
    border-top: 1px solid #E5E7EB;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.setting-tab-title{
    font-weight: 500;
    font-size: 16px;
    width: 300px;
}
.setting-tab-content{
    flex-grow: 1;
}
.setting-tab-content input[type=text], .setting-tab-content textarea, .setting-tab-content select, .admin-panel input[type=text], input.search, select.datefilter, input.discount{
    width: 300px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #E5E7EB;
    margin-bottom: 10px;
    
    font-family: 'Inter', sans-serif;
}
.setting-tab-content textarea{
    height: 100px;
    resize: none;
    font-family: 'Inter', sans-serif;
}
.setting-tab-content select{
    height: 40px;
    font-family: 'Inter', sans-serif;
}
/* checkbox */
.setting-tab-checkbox{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.setting-tab-checkbox input{
    margin-right: 10px;
}
.setting-tab-checkbox label{
    font-size: 14px;
    line-height: 21px;
}
.generate.yellow{
    background-color: #FFC200;
    color: #000;
}
.setting-submit-container{
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
}
a{
    text-decoration: none;
    color: #000;
}
a *{
    text-decoration: none;
    color: #000;
}
.newsletter-page{
    background-color: white;
    padding: 10px 30px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 20px;
    margin: 0 auto;
    width: 800px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
    text-align: left;
}
.newsletter-page img{
    width: 100%;
    max-height: 350px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
}
.newsletter-page a{
    color: #7947E3;
}
.newsletter-actions{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
#copyhtml{
}
.main.newsissue{
    text-align: center;
}
.feedback{
    background-color: #FFC200;
    color: #000;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 8px;
}
.feedback-notice{
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
    /*white slightly grey*/
    color: #aeb5c2;
    margin-top: 5px;
}
.user-button-container{
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    background-color: #1B1829;
    padding: 14px 20px;
    border-radius: 8px;
    align-items: center;
}
.user-button-container span{
    font-size: 14px;
    line-height: 21px;
    color: #FFF;
    margin-right: 20px;
    display: block;
    margin-left: 20px;
}
.code{
    font-size: 14px;
    line-height: 21px;
    color: #FFC200;
    display: block;
    background-color: #000;
    border-radius: 8px;
    width: 300px;
    padding: 15px 20px;
    cursor: pointer;
}
a.simplelink{
    color: #FFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-decoration: underline;
}
.roadmap-p, .feedback-notice{
    margin: 0;
}
.feedback-notice{
    margin-bottom: 10px;
}


.notif_bar{
	position: fixed;
	width: 100vw;
	text-align: center;
	padding-top: 12px;
	padding-bottom: 12px;
	background-color: #82C263;
	color: #FFF;
	z-index: 99;
    display:none;
}
.notif_bar.redalert{
	background-color: #E04A3A;
}

  .usagetitlecontainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 20px;
  }
  p.smalltitle{
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #FFC200;
    margin: 0;
  }
  .smallbutton{
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    padding: 3px 10px 4px 10px;
    border-radius: 5px;
    background-color: #FFC200;
    color: #000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .noblocks{
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 5px;
    color: #555;
    margin: 0;
    margin-top: 10px;
  }
  .noblocks a, .description a{
    color: #FFC200;
    font-weight: 500;
  }

  .sidebar.onboarding{
    margin-right: 0;
  }
  .main.onboarding{
    overflow-y: auto;
  }

  .welcome{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  .welcome>p{
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 5px;
    color: #555;
    margin: 0; 
    max-width: 500px;
    text-align: justify;
    margin-bottom: 20px;
  }
  .welcome>*:last-child{
    margin-bottom: 100px;
  }
  .button{ /* yellow */
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #FFC200;
    color: #000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
.button:hover{
    background-color: #FFD633;
}
.smalllink{
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #FFC200;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 20px;
}
.welcome h1{
    margin-bottom: 40px;
}
.welcome .code{
    margin-bottom: 20px;
    height: auto;
}

p.italic{
    font-style: italic;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 5px;
    color: #555;
    margin: 0;
    margin-top: 10px;
}

.oneblock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    flex-direction: column;
}
.oneblock .block{
    width: 100%;
    max-width: 300px;
    margin-bottom: 30px;
}

.overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    z-index: 98;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.popup-inside{
    background-color: #FFF;
    padding: 20px 40px;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    position: relative;
    cursor: default;
}
.admin-panel .button{
    margin-bottom: 10px;
    width: 200px;
}
.admin-panel a.simplelink{
    color: #000;
    display: inline-block;
}
.userfetchform{
    display: flex;
    justify-content: flex-start;
}
.userfetchform .button{
    margin-left: 10px;
}
.workspaces select{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #E5E7EB;
    font-family: 'Inter', sans-serif;
    margin-top: 5px;
}
.withbutton{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.withbutton .button{
    margin-left: 10px;
    width: fit-content;
    text-align: center;
}
.topbar{
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 8px 20px;
    border-radius: 0 0 0 8px;
    cursor: pointer;
}
.topbar p{
    margin: 0;
    padding: 0;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 300;
}
.topbar p span{
    font-weight: 600;
}
.tweet{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 100%;
    flex-direction: column;
    background-color: #F4F8FB;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}
.tweet:first-child{
    margin-top: 20px;
}
.set-order{
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 15px;
}
.set-order .block h3{
    -webkit-line-clamp: 1;
    margin-bottom: 0;
    width: auto;
    margin-right: 20px;
}
.set-order .block{
    padding: 10px 20px;
    flex-grow: 1;
}
.set-order .order-line{
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.set-order .block .heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.up-down{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
}
.up-down img{
    width: 20px;
    height: fit-content;
    cursor: pointer;
}

.two_buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.two_buttons .generate{
    width: 50%;
}
.intro-outro .setting-tab{
    padding-bottom: 0;
}
.setting-checkbox{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #555;
}
.setting-checkbox input{
    margin-right: 10px;
}
.setting-checkbox label{
    margin-bottom: 0;
}

.intro-outro{
    padding-bottom: 15px;
}
img.searchicon{
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin-right: -31px;
    color: #CCC;
    z-index: 99;
    position: relative;
    margin-top: 8px;
}
input.search{
    padding-left: 40px;
}
select.datefilter{
    margin-left: 10px;
}
.searchbox{
    display:flex;
    padding-left: 6px;
    margin-top: -10px;
}

@media screen and (max-height: 750px){
    .menu a{
        padding: 8px 20px;
        margin-bottom: 7px;
    }
}
.newsfeeds{
    border-top: 1px solid #E5E7EB;
    padding-top: 15px;
}
/*<div className='feed'>
            <div className='feed-title'>
                <div className='feed-title-text'>{newsfeed.name}</div>
                <div className='feed-title-actions'>
                    <div className='feed-title-action' onClick={deleteNewsfeed}>Delete</div>
                </div>
            </div>
            <div className='feed-content'>
                <div className='feed-content-text'>{newsfeed.url}</div>
            </div>
        </div>*/

.feed{
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    font-family: 'Inter', sans-serif;
    padding: 25px;
    margin-top: 10px;
}
.feed h3{
    margin: 0;
    padding: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 220px;
    margin-top: 0;
}
.feed>p{
    margin-top: 0;
    margin: 0;
    color: #6B7280;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

}
.feed .buttons{
    margin-top: -30px;
}
.newsfeeds.news .feed .buttons{
    margin-top: 18px;
}

.newsfeeds.news{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    border-top: 1px solid #E5E7EB;
    padding-top: 15px;
}

.center{
    text-align: center;
}
.title.center{
    margin-top: 50px;
    margin-bottom: 25px;
}
.description.center{
    width: 100%;
    max-width: unset;
}
.period-tabs{
    margin: 0 auto;
    border-radius: 22px;
    background: #FFF;
    display: flex;
    width: 220px;
    margin-top: 30px;
}
.period-tab{
    
    width: 50%;
    color: #0A0B1E;

    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.833px;
    padding: 15px 0;
    text-transform: uppercase;
    cursor: pointer;
}
.period-tab.active{
    background-color: #0A0B1E;
    color: #FFF;
    border-radius: 22px;
}

.pricing-container{
    display:flex;
    box-sizing: border-box;
    max-width: 1150px;
    margin: 0 auto;
    border-radius: 26px;
background: #FFF;
    margin-top: 70px;
    margin-bottom: 50px;
}
.pricing{
    box-sizing: border-box;
    padding: 50px 50px;
    width: 25%;
}
.pricing .price{
    font-family: 'Inter';
    color: #231D4F;

font-size: 33px;
font-style: normal;
font-weight: 700;
line-height: 46px;
margin-bottom: 25px;
margin-top: -10px
}
.pricing .price>span{
    color: #848199;

font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.pricing .title{
    color: #231D4F;

    font-family: 'Inter';
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}
.pricing .description{
    color: #848199;
    font-size: 14px;
    font-weight: 400;
}
.features .feature{
    display:flex;
    margin-bottom: 10px;
}
.features .feature img{
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin-right: 10px;
    color: #FFF;
}
.pricing .button{
    border-radius: 24px;
background: #0A0B1E;
color: #FFF;

text-align: center;
font-family: 'Inter';
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 25px;
}

.pricing.popular{
    background-color: #0A0B1E;
    color: #FFF;
    margin-top: -30px;
    padding-top: 20px;
    border-radius: 26px;
}
.pricing.popular .price{
    color: #FFF;
}
.pricing.popular .title{
    color: #FFF;
}
.pricing.popular .description{
    color: #FFF;
}
.pricing.popular .button{
    background-color: #FFF;
    color: #0A0B1E;
}
.pricing .highlight{
    border-radius: 17px;
    background: #FFC200;
    color: #0A0B1E;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.833px;
    padding: 4px 13px;
    text-transform: uppercase;
    margin-bottom: 30px;
    width: fit-content;
    margin-left: auto;
    margin-right: -30px;
}

.setting-tab.actions .button{
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: 10px;
    width: 180px;
    border-radius: 8px;
    font-family: 'Inter';
    display: block;
}

.barred{
    text-decoration: line-through;
}
.price.off{
    margin-top: -40px;
    margin-bottom: 0;
}

.discount_container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
input.discount{
    margin-bottom: 0;
}
.discount_container h3{
    margin-right: 13px;
    font-family: 'Inter';
    font-size:15px;
    font-weight: 500;
}
.newsletter-actions .generate {
    padding: 10px 15px;
    font-size: 14px;
}